import React, {useEffect, useState} from 'react';
import {ActionIcon, Box, Flex, Menu, Radio, Select, TextInput} from '@mantine/core';
import { IconCode } from '@tabler/icons';
import VariableMenu from "../../../GlobalComponent/VariableMenu";
import VariableSelectWrite from "../../../GlobalComponent/VariableSelectWrite";
import VariableSelect from "../../../GlobalComponent/VariableSelect";
import VariableNumberInput from "../../../GlobalComponent/VariableNumberInput";
import VariableSelectCreate from "../../../GlobalComponent/VariableSelectCreate";

const ForCode = (props) => {
    const [opened, setOpened] = useState(false);
    const [opened2, setOpened2] = useState(false);
    const [dataState, setDataState] = useState(props?.nodeData?.data?.options??{
        selectorTypeLoop: 'data',
        elementSelect: '',
        extractionType: "text",
        extractionTypeFill: "",
        loopItemSave:"",
        loopIndexSave:"",
        fromValue: "",
        toValue: "",
        times: 10
    })

    const handleChange = (type, value) => {
        if (typeof value === 'string') {
            setDataState({...dataState, [type]: value});
        } else if (typeof value === 'object' && value === null ) {
            setDataState({...dataState, [type]: ""});
        } else {
            setDataState({...dataState, [type]: value.target.value});
        }
    }

    useEffect(() => {
        props.handleSetDataBaseModal(dataState)
    }, [props, dataState])

    const rightSection = (<ActionIcon radius="md"><IconCode size={16} /></ActionIcon>)

    return (
        <Box>
            <Radio.Group
                value={dataState.selectorTypeLoop}
                label="Choose type loop"
                onChange={(e) => {
                    handleChange('selectorTypeLoop', e)
                }}
                name="for-code"
            >
                <Radio value="data" label="Data" />
                <Radio value="element" label="Elements" />
                <Radio value="times" label="Times" />
            </Radio.Group>
            {dataState.selectorTypeLoop === "data" &&
                <Box>
                    <VariableSelectWrite
                        label="For from value"
                        placeholder="Enter number or insert variable"
                        dataState={dataState.fromValue}
                        setDataState={handleChange}
                        handleData={(e) => {
                            handleChange('fromValue', e)
                        }}
                        handleSelect={(e) => {
                            handleChange('fromValue', e.newValue)
                        }}
                    />
                    <VariableSelectWrite
                        style={{marginTop: "20px"}}
                        label="For to value"
                        placeholder="Enter number or insert variable"
                        dataState={dataState.toValue}
                        setDataState={handleChange}
                        handleData={(e) => {
                            handleChange('toValue', e)
                        }}
                        handleSelect={(e) => {
                            handleChange('toValue', e.newValue)
                        }}
                    />
                </Box>
            }
            {dataState.selectorTypeLoop === "element" &&
                <Box>
                    <VariableSelectWrite
                        style={{marginBottom:"10px"}}
                        dataState={dataState.elementSelect} label="Select element" placeholder="Please enter the element, such as #email input"
                        setDataState={handleChange}
                        handleData={(e) => {
                            handleChange('elementSelect', e)
                        }}
                        handleSelect={(e) => {
                            handleChange('elementSelect', e.newValue)
                        }}
                    />
                    <Flex
                        mih={50}
                        gap="md"
                        justify="flex-start"
                        align="flex-start"
                        direction="row"
                        wrap="wrap"
                    >
                        <Select
                            value={dataState.extractionType}
                            label="Content"
                            placeholder="Pick one"
                            style={{width:"150px", marginTop:"10px"}}
                            data={[
                                { value: 'text', label: 'Text' },
                                { value: 'html', label: 'Html' },
                                { value: 'attribute', label: 'Attribute' },
                            ]}
                            onChange={(e) => {
                                handleChange('extractionType', e)
                            }}
                        />
                        {(dataState.extractionType === "attribute" || dataState.extractionType === "childrenNode") &&
                            <VariableSelectWrite
                                dataState={dataState.extractionTypeFill}
                                label=" "
                                placeholder="Please fill in to..."
                                setDataState={handleChange}
                                handleData={(e) => {
                                    handleChange('extractionTypeFill', e)
                                }}
                                handleSelect={(e) => {
                                    handleChange('extractionTypeFill', e.newValue)
                                }}
                            />
                        }
                    </Flex>
                    <VariableSelectCreate
                        style={{marginTop: "10px"}}
                        label="Loop object save to"
                        value={dataState.loopItemSave}
                        handleChange={(e) => {
                            handleChange('loopItemSave', e)
                        }}
                    />
                    <VariableSelectCreate
                        style={{marginTop: "10px"}}
                        label="Loop index save to (Index start from 0)"
                        value={dataState.loopIndexSave}
                        handleChange={(e) => {
                            handleChange('loopIndexSave', e)
                        }}
                    />
                </Box>
            }
            {dataState.selectorTypeLoop === "times" &&
                <Box>
                    <VariableNumberInput
                        label="Times"
                        dataState={dataState.times}
                        handleData={(e) => {
                            handleChange('times', e)
                        }}
                    />
                    <VariableSelectCreate
                        style={{marginTop: "10px"}}
                        label="Loop index save to"
                        value={dataState.loopIndexSave}
                        handleChange={(e) => {
                            handleChange('loopIndexSave', e)
                        }}
                    />
                </Box>
            }
        </Box>
    );
}

export default ForCode;
