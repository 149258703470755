import {createContext, useState, useEffect} from "react";
import { getAllScript } from "../../request/Automation";
const RunOtherScriptContext = createContext();
function RunOtherScriptProvider({children}) {
    const [search, setSearch] = useState('')
    const [listScript, setListScript] = useState([])
    const [valueChoose, setValueChoose] = useState('')
    
    useEffect(() => {
        getAllScript(search).then(res => {
            const scriptOptions = res.map((script) => ({ ...script, value: script.key2, label: script.name }))
            setListScript(scriptOptions)
        })
    }, [search])

    const value = {
        search,
        listScript,
        valueChoose,
        setSearch,
        setListScript,
        setValueChoose
    }

    return (
        <RunOtherScriptContext.Provider value={value}>
            {children}
        </RunOtherScriptContext.Provider>
    )
}

export {RunOtherScriptContext, RunOtherScriptProvider}