import { useEffect, useState } from "react";
import { Button, Grid, Select } from "@mantine/core";
import VariableSelectWrite from "../../../GlobalComponent/VariableSelectWrite";
import { IconPlus, IconTrash } from "@tabler/icons";
import { v4 as uuid } from "uuid";
import { useForm } from "@mantine/form";
import { cloneDeep } from "lodash"
import VariableSelect from "../../../GlobalComponent/VariableSelect";

const defaultState = {
  conditions: [{
    id: uuid(),
    leftVariable: "",
    operator: "",
    rightVariable: "",
  }]
}

function IfCodeV2(props) {
  const defaultOptions = props?.nodeData?.data?.options ?? defaultState
  const [dataState, setDataState] = useState(defaultOptions)

  const form = useForm({
    initialValues: {
      conditions: props?.nodeData?.data?.options?.conditions ?? defaultState.conditions,
    },
  });

  const onChangeFormValue = (key, index) => (e) => {
    const formKey = 'conditions'
    const newRowValue = cloneDeep(form.values[formKey][index]);
    if (e?.type === 'change') {
      newRowValue[key] = e.target.value;
    } else if (e?.type === "click") {
      const newValue = e.newValue || ""
      newRowValue[key] = newValue;
    } else {
      newRowValue[key] = e;
    }
    if (key === "type") {
      newRowValue.value = "";
    }
    const newBody = cloneDeep(form.values[formKey]);
    newBody[index] = newRowValue;
    form.setFieldValue(formKey, newBody);
    setDataState((state) => ({ ...state, [formKey]: newBody }))
  }

  const removeRow = (id, index) => {
    const newRows = form.values.conditions.filter(({ id: itemId }) => itemId !== id);
    form.setFieldValue('conditions', newRows);
    setDataState((state) => ({ ...state, conditions: newRows }))
    props.setEdges((edges) => {
      const newEdges = [];
      for (const edge of edges) {
        if (edge.source === props?.nodeData.id) {
          const edgeOrder = Number(edge.sourceHandle.split('-')[2])
          if (index !== edgeOrder) {
            newEdges.push(edge)
          }
          if (edgeOrder > index) {
            edge.sourceHandle = `success-condition-${edgeOrder-1}-${props?.nodeData.id}`
          }
        } else {
          newEdges.push(edge)
        }
      }
      return newEdges
    })
  };

  const addNewRow = () => {
    const storeVariable = [...form.values.conditions];
    const newColumn = [
      ...storeVariable,
      { leftVariable: "", operator: "", rightVariable: "", id: uuid() },
    ]
    form.setFieldValue('conditions', newColumn);
    setDataState((state) => ({ ...state, conditions: newColumn }))
  };

  useEffect(() => {
    props.handleSetDataBaseModal({ ...dataState, conditions: form.values.conditions })
  }, [props, dataState])

  return (
    <>
      {form.values.conditions.map(({ id, leftVariable, operator, rightVariable }, index) => {
        return (
          <Grid
            grow
            align="flex-end"
            key={index}
            mt="xs"
          >
            <Grid.Col span={4}>
              <VariableSelect
                label="Variable"
                placeholder="Select left operand"
                value={leftVariable}
                handleChange={onChangeFormValue('leftVariable', index)}
              />
            </Grid.Col>
            <Grid.Col span={2}>
              <Select
                style={{ marginTop: "10px" }}
                label="Operator"
                placeholder="Select operator"
                value={operator}
                onChange={onChangeFormValue('operator', index)}
                data={[
                  { value: '<', label: '<' },
                  { value: '>', label: '>' },
                  { value: '=', label: '=' },
                  { value: '!=', label: '!=' },
                  { value: '<=', label: '<=' },
                  { value: '>=', label: '>=' },
                  { value: 'startsWith', label: 'Starts with' },
                  { value: 'endsWith', label: 'Ends with' },
                  { value: 'contains', label: 'Contains' },
                ]}
              />
            </Grid.Col>
            {(operator !== 'exist' && operator !== 'notExist') &&
              <Grid.Col span={4} >
                <VariableSelectWrite
                  label="Variable or value"
                  placeholder="Select right operand"
                  dataState={rightVariable}
                  handleData={onChangeFormValue('rightVariable', index)}
                  handleSelect={onChangeFormValue('rightVariable', index)}
                />
              </Grid.Col>
            }
            <Grid.Col span={1}>
              {form.values.conditions.length > 1 ? (
                <Button
                  color="red"
                  onClick={() => removeRow(id, index)}
                >
                  <IconTrash size={20} />
                </Button>
              ) : null}
            </Grid.Col>
          </Grid >
        )
      })}
      <Button
        rightIcon={<IconPlus size={16} />}
        mt="sm"
        onClick={addNewRow}
      >
        Add
      </Button>
    </>
  );
}

export default IfCodeV2;