import { useContext, useEffect, useRef } from "react";
import ComponentCombobox from "../../../../GlobalComponent/ComponentCombobox";
import { RunOtherScriptContext } from "../../../../../core/context/RunOtherScriptContext"

function IndexRunOtherScript({setDataState, dataState}) {
    const { listScript, valueChoose, setValueChoose, setSearch } = useContext(RunOtherScriptContext)
    const debounceTimeout = useRef(null);

    const handleSetValue = (item) => {
        if (typeof item === 'string') {
            setValueChoose(item)
            console.log(debounceTimeout.current)
            if (debounceTimeout.current !== null) {
                clearTimeout(debounceTimeout.current);
            }
            debounceTimeout.current = setTimeout(() => {
                setSearch(item)
            }, 1000);
            
        } else if (typeof item === 'object') {
            let script = listScript.find(i => i.key2 === item.key2)??null
            if (script) { 
                let name = script.name
                if (name.length > 15) {
                    name = script.name.slice(0, 15) + "..."
                }
                setDataState({ ...script, script_key: item.key2, description: name })
                setValueChoose(script.name)
            }
        }
    }

    useEffect(() => {
        setValueChoose(dataState.name ?? '')
    },[dataState])
    
    return (
        <div>
        <ComponentCombobox
             data={listScript}
             placeholder="Pick one"
             label="Script to be execute"
             withAsterisk={true}
             handleData={(e) => handleSetValue(e)}
             dataState={valueChoose}
         />
        </div>
    )
       
}

export default IndexRunOtherScript;