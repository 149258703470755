import axios from "axios";
import CryptoJS from 'crypto-js';
import {BASE_URL, BASE_URL_V4, GET_ALL_FOLDER_BY_USER, DEFAULT_CONFIG, LIST_PROFILE_NAME, LOCAL_LIST_PROFILE_NAME, SCRIPT, STATUS_PROFILE, TAG, UPDATE_SCRIPT, USER_SETTINGS} from "../constants/api";
let ListProfileNameController

export const SALT_SCRIPT_STORE = process.env.REACT_APP_SALT_SCRIPT_STORE

export const encodeScript = async (scriptContent = '') => {
    try {
        if (window.electron.ipcRenderer.invoke) {
            const script_content = await window.electron.ipcRenderer.invoke('encript', { data: scriptContent });
            return script_content
        } else {
            console.log('window.electron.ipcRenderer.invoke', window.electron.ipcRenderer.invoke);
            return scriptContent
        }
    } catch (error) {
        console.log({error});
        return scriptContent
    }
}

export class Encryption {
    /**
     * @const integer Return encrypt method or Cipher method number. (128, 192, 256)
     */
    get encryptMethodLength() {
        const encryptMethod = this.encryptMethod;
        // get only number from string.
        // @link https://stackoverflow.com/a/10003709/128761 Reference.
        const encriptResult = encryptMethod.match(/\d+/)
        const aesNumber = encriptResult ? encriptResult[0] : '';
        return parseInt(aesNumber);
    } // encryptMethodLength

    /**
     * @const integer Return cipher method divide by 8. example: AES number 256 will be 256/8 = 32.
     */
    get encryptKeySize() {
        const aesNumber = this.encryptMethodLength;
        return parseInt((aesNumber / 8).toString());
    }// encryptKeySize

    /**
     * @link http://php.net/manual/en/function.openssl-get-cipher-methods.php Refer to available methods in PHP if we are working between JS & PHP encryption.
     * @const string Cipher method.
     *              Recommended AES-128-CBC, AES-192-CBC, AES-256-CBC
     *              due to there is no `openssl_cipher_iv_length()` function in JavaScript
     *              and all of these methods are known as 16 in iv_length.
     */
    get encryptMethod() {
        return 'AES-256-CBC';
    }// encryptMethod

    /**
     * Decrypt string.
     *
     * @link https://stackoverflow.com/questions/41222162/encrypt-in-php-openssl-and-decrypt-in-javascript-cryptojs Reference.
     * @link https://stackoverflow.com/questions/25492179/decode-a-base64-string-using-cryptojs Crypto JS base64 encode/decode reference.
     * @param string encryptedString The encrypted string to be decrypt.
     * @param string key The key.
     * @return string Return decrypted string.
     */
    decrypt(encryptedString, key=SALT_SCRIPT_STORE) {
        const json = JSON.parse(CryptoJS.enc.Utf8.stringify(CryptoJS.enc.Base64.parse(encryptedString)));

        const salt = CryptoJS.enc.Hex.parse(json.salt);
        const iv = CryptoJS.enc.Hex.parse(json.iv);

        const encrypted = json.ciphertext;// no need to base64 decode.

        let iterations = parseInt(json.iterations);
        if (iterations <= 0) {
            iterations = 999;
        }
        const encryptMethodLength = (this.encryptMethodLength/4);// example: AES number is 256 / 4 = 64
        const hashKey = CryptoJS.PBKDF2(key, salt, {'hasher': CryptoJS.algo.SHA512, 'keySize': (encryptMethodLength/8), 'iterations': iterations});

        const decrypted = CryptoJS.AES.decrypt(encrypted, hashKey, {'mode': CryptoJS.mode.CBC, 'iv': iv});

        return decrypted.toString(CryptoJS.enc.Utf8);
    }// decrypt

    /**
     * Encrypt string.
     *
     * @link https://stackoverflow.com/questions/41222162/encrypt-in-php-openssl-and-decrypt-in-javascript-cryptojs Reference.
     * @link https://stackoverflow.com/questions/25492179/decode-a-base64-string-using-cryptojs Crypto JS base64 encode/decode reference.
     * @param string string The original string to be encrypt.
     * @param string key The key.
     * @return string Return encrypted string.
     */
    encrypt(string, key=SALT_SCRIPT_STORE) {
        const iv = CryptoJS.lib.WordArray.random(16);// the reason to be 16, please read on `encryptMethod` property.

        const salt = CryptoJS.lib.WordArray.random(256);
        const iterations = 999;
        const encryptMethodLength = (this.encryptMethodLength/4);// example: AES number is 256 / 4 = 64
        const hashKey = CryptoJS.PBKDF2(key, salt, {'hasher': CryptoJS.algo.SHA512, 'keySize': (encryptMethodLength/8), 'iterations': iterations});

        const encrypted = CryptoJS.AES.encrypt(string, hashKey, {'mode': CryptoJS.mode.CBC, 'iv': iv});
        const encryptedString = CryptoJS.enc.Base64.stringify(encrypted.ciphertext);

        const output = {
            'ciphertext': encryptedString,
            'iv': CryptoJS.enc.Hex.stringify(iv),
            'salt': CryptoJS.enc.Hex.stringify(salt),
            'iterations': iterations
        };

        return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(JSON.stringify(output)));
    } // encrypt
}

const EncriptionInstance = new Encryption()

function b64DecodeUnicode(str) {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(atob(str).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
}

function decryptHandle (string) {
    const decripted = EncriptionInstance.decrypt(string)
    return b64DecodeUnicode(decripted)
}

export const decodeScript = async (scriptContent = '') => {
    try {
        JSON.parse(scriptContent)
        return scriptContent
    } catch (error) {
        console.log(error);
    }
    try {
        if (window.electron.ipcRenderer.invoke) {
            const script_content = await window.electron.ipcRenderer.invoke('decript', { data: scriptContent });
            return script_content
        } else {
            console.log('window.electron.ipcRenderer.invoke', window.electron.ipcRenderer.invoke);
            return scriptContent
        }
    } catch (error) {
        console.log({error});
    }
    try {
        const script_content = decryptHandle(scriptContent)
        return script_content
    } catch (error) {
        return scriptContent
    }
}

export const getAllFolderByUser = async () =>{    
    return axios({
        url: localStorage.getItem('base_url_v4' ?? 'http://localhost:2222/v4') + GET_ALL_FOLDER_BY_USER + `?limit=10000`,
        method: "get"
    })
}

export const createScript = ({ body, headers }) => {
    return axios({
        url: BASE_URL + SCRIPT,
        method: "post",
        data: body,
        headers: headers,
    });
}

export const updateScript = ({ body, id, headers }) => {
    return axios({
        url: BASE_URL + `${UPDATE_SCRIPT}/${id}`,
        method: "post",
        data: body,
        headers: headers,
    });
}

export const findScriptById = ({params, id, headers}) => {
    return axios({
        url: BASE_URL + `${SCRIPT}/${id}`,
        method: "get",
        params: params,
        headers: headers,
    });
}

export const getScript = async ({ page, limit, search }) => {
    const url = BASE_URL + SCRIPT
    const { data } = await axios({
        url: url,
        method: "get",
        params:{
            limit:limit,
            page:page,
            folder_script_id:'get_all',
            search: search
        }
    });
    return data
}

export const getAllScript = async (search) => {
    let page = 1
    let limit = 20
    const { data } = await getScript({ page, limit, search })
    return data.content
}

export const listProfileName = (limit, search, is_local = false) => {
    let url = BASE_URL + LIST_PROFILE_NAME;
    ListProfileNameController?.abort()
    ListProfileNameController = new AbortController();
    return axios({
        url: url,
        method: "get",
        params: {
            limit: limit,
            search: search,
            is_local,
        },
        signal: ListProfileNameController.signal
    });
}

export const fetchListStatus = async () => {
    const { data } = await axios.get(BASE_URL + STATUS_PROFILE);
    return data.content;
}

export const fetchListTag = async () => {
    const { data } = await axios.get(BASE_URL + TAG);
    return data.content;
}

export const getUserSettings = async () => {
    const { data } = await axios.get(BASE_URL + USER_SETTINGS, {
        headers: {
            'Version': window?.api?.configs?.app_version,
        }
    });
    const osConfig = data.content.find((item) => item.key === "USER_AGENT_CONFIG")
    return osConfig.value.os;
}

export const fetchDefaultConfig = async () => {
    const { data } = await axios.get(BASE_URL + DEFAULT_CONFIG);
    return data.data.content;
}
