import React, { useEffect, useRef, useState } from "react";
import { Input, Radio, Select, TextInput } from "@mantine/core";
import { IconCode, IconFileUpload } from "@tabler/icons";
import VariableSelectWrite from "../../../GlobalComponent/VariableSelectWrite";

const WriteFile = (props) => {
    const inputRef = useRef(null)
    const [dataState, setDataState] = useState(props?.nodeData?.data?.options ?? {
        filePath: "",
        inputVariable: "",
        writeMode: "overwrite",
        selectorType: "",
        lineByLineDelimiter: "",
        appendMod: "newline",
        lineByLineDelimiterTxt: ""
    });

    const handleSelector = (type, value) => {
        if (typeof value === 'string') {
            setDataState({...dataState, [type]: value});
        } else {
            if (value.target.hasOwnProperty('checked')) {
                setDataState({...dataState, [type]: value.target.checked});
            } else {
                setDataState({...dataState, [type]: value.target.value});
            }
        }
    }

    useEffect(() => {
        props.handleSetDataBaseModal(dataState)
    }, [props, dataState])

    const handleUpload = () => {
        setDataState({...dataState, filePath: inputRef.current.files[0].path});
        console.log(inputRef.current.files[0].path)
    }

    return (
        <>
            <div className="custom-wrapper">
                <div className="block-input">
                    <VariableSelectWrite
                        placeholder="Enter full path or upload file"
                        label="File path"
                        description="File path must be absolute path"
                        dataState={dataState.filePath}
                        setDataState={handleSelector}
                        handleData={(e) => {
                            handleSelector('filePath', e)
                        }}
                        handleSelect={(e) => {
                            handleSelector('filePath', e.newValue)
                        }}
                    />
                </div>
                <Input
                    className="custom-file-input"
                    type="file"
                    ref={inputRef}
                    onChange={handleUpload}
                    icon={<IconFileUpload size={16} />}
                    mt="0px"
                    styles={(theme) => ({
                        input: {
                            paddingRight: "0px",
                            '&::-webkit-file-upload-button': {
                                visibility: 'hidden',
                            },
                            cursor: 'pointer',
                        },
                    })}
                >
                </Input>
            </div>
            <VariableSelectWrite
                placeholder="Enter input data"
                label="Select input data from variable"
                dataState={dataState.inputVariable}
                setDataState={handleSelector}
                handleData={(e) => {
                    handleSelector('inputVariable', e)
                }}
                handleSelect={(e) => {
                    handleSelector('inputVariable', e.newValue)
                }}
            />
            <Radio.Group
                label="Selector type"
                mt="xs"
                withAsterisk
                value={dataState.selectorType}
                onChange={(e) => {
                    handleSelector('selectorType', e)
                }}
            >
                <Radio value="txt" label="TXT" />
                <Radio value="csv" label="CSV" />
                <Radio value="json" label="JSON" />
            </Radio.Group>
            {(() => {
                switch (dataState.selectorType) {
                    case 'json':
                    case 'txt':
                        return <Radio.Group
                            label="Select write mode"
                            value={dataState.writeMode}
                            mt="xs"
                            onChange={(e) => {
                                handleSelector('writeMode', e)
                            }}
                        >
                            <Radio value="overwrite" label="Overwrite" />
                            <Radio value="append" label="Append" />
                        </Radio.Group>
                    case 'csv':
                        return <>
                            <TextInput
                                mt="xs"
                                label="CSV delimiter"
                                value={dataState.lineByLineDelimiter}
                                onChange={(e) => {
                                    handleSelector('lineByLineDelimiter', e)
                                }}
                                placeholder="Enter delimiter - default comma (,)"
                            />
                            <Radio.Group
                                label="Select write mode"
                                value={dataState.writeMode}
                                mt="xs"
                                onChange={(e) => {
                                    handleSelector('writeMode', e)
                                }}
                            >
                                <Radio value="overwrite" label="Overwrite" />
                                <Radio value="append" label="Append" />
                            </Radio.Group>
                        </>
                    default:
                        return null
                }
            })()}
            {dataState.selectorType === 'txt' && dataState.writeMode === 'append' &&
                <Radio.Group
                    label="Select append mode"
                    value={dataState.appendMod}
                    mt="xs"
                    onChange={(e) => {
                        handleSelector('appendMod', e)
                    }}
                >
                    <Radio value="newline" label="New line" />
                    <Radio value="sameline" label="Same line" />
                </Radio.Group>
            }
            {dataState.selectorType === 'txt' && dataState.writeMode === 'append' && dataState.appendMod === 'sameline' &&
                <TextInput
                    mt="xs"
                    label="Delimiter"
                    value={dataState.lineByLineDelimiterTxt}
                    onChange={(e) => {
                        handleSelector('lineByLineDelimiterTxt', e)
                    }}
                    placeholder="Enter delimiter - default comma (,)"
                />
            }
        </>
    )
}
export default WriteFile