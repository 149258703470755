import React, { useState, useEffect, useContext } from "react";
import { Group, NumberInput, Radio, Select, Slider, Text } from "@mantine/core";
import { v4 as uuid } from 'uuid';
import {DataFlowContext} from "../../../../core/context/DataFlowContext";
import VariableSelectWrite from "../../../GlobalComponent/VariableSelectWrite";
import VariableNumberInput from "../../../GlobalComponent/VariableNumberInput";

const dataTypes = [
    { value: 'email', label: 'Email' },
    { value: 'fullName', label: 'Full name' },
    { value: 'alphabet', label: 'Random Letters' },
    { value: 'password', label: 'Password' },
    { value: 'firstName', label: 'First name' },
    { value: 'lastName', label: 'Last name' },
    { value: 'number', label: 'Number' },
]

const Random = (props) => {
    const [dataState, setDataState] = useState(props?.nodeData?.data?.options ?? {
        modeValue: props?.nodeData?.data?.options?.modeValue || "",
        password: props?.nodeData?.data?.options?.password || "",
        count: props?.nodeData?.data?.options?.count || "",
        to: props?.nodeData?.data?.options?.to || "",
        from: props?.nodeData?.data?.options?.from || "",
        outputVariable: props?.nodeData?.data?.options?.outputVariable || "",
        domain: props?.nodeData?.data?.options?.domain || "",
    })
    const data = useContext(DataFlowContext)

    const newData = data.nodeValue.variables.filter(variable => variable.label !== "")

    const handleChange = (key) => (e) => {
        if (e.type === 'change') {
            setDataState((state) => ({...state, [key]: e.target.value}))
        } else if (e.type === 'click') {
            const newValue = e.newValue || ""
            setDataState((state) => ({...state, [key]: newValue}))
        } else {
            setDataState((state) => ({...state, [key]: e}))
        }
    }

    const handleSelector = (type, value) => {
        console.log({type, value})
        if (typeof value === 'string' || typeof value === 'number') {
            setDataState({...dataState, [type]: value});
        } else {
            setDataState({...dataState, [type]: value.target.value});
        }
    }

    useEffect(() => {
        props.handleSetDataBaseModal(dataState)
    }, [dataState, props])

    return (
        <>
            <Select
                data={dataTypes}
                value={dataState.mode}
                label="Choose one"
                onChange={mode => setDataState(s => ({ ...s, mode }))}
            />
            {(() => {
                switch (dataState.mode) {
                    case 'password':
                    case 'alphabet':
                        return <>
                            <Text
                                fw={500}
                                mt="xs"
                            >
                                Select symbols quantity
                            </Text>
                            <Slider
                                mt="xs"
                                min={4}
                                max={64}
                                value={dataState.password}
                                onChange={password => setDataState(s => ({ ...s, password}))}
                                marks={[
                                    { value: 8, label: '8' },
                                    { value: 16, label: '16' },
                                    { value: 32, label: '32' },
                                    { value: 40, label: '40' },
                                ]}
                            />
                        </>
                    case 'number':
                        return <>
                            <Group mt="xs">
                                <VariableNumberInput
                                    label="From number"
                                    dataState={dataState.from}
                                    handleData={(e) => {
                                        handleSelector('from', e)
                                    }}
                                    isMillisecond={true}
                                />
                                <VariableNumberInput 
                                    label="To number"
                                    dataState={dataState.to}
                                    handleData={(e) => {
                                        handleSelector('to', e)
                                    }}
                                    isMillisecond={true}
                                /> 
                            </Group>
                        </>
                    case 'email':
                        return <>
                            <VariableSelectWrite
                                label="Domain"
                                dataState={dataState.domain}
                                placeholder="Ex: outlook.com"
                                handleData={handleChange("domain")}
                                handleSelect={handleChange("domain")}
                            />
                        </>
                    default:
                        return null
                }
            })()}
            <Select
                label="Output Variable"
                data={newData}
                placeholder="Select items"
                nothingFound="Nothing found"
                searchable
                mt="lg"
                value={dataState.outputVariable}
                onChange={outputVariable => setDataState(s => ({ ...s, outputVariable}))}
                creatable
                getCreateLabel={(query) => `+ Create ${query}`}
                onCreate={(query) => {
                    const item = { id: uuid(), value: query, label: query, data: "", name: "" };
                    data.setNodeValue({
                        variables: [...data.nodeValue.variables, item]
                    })
                    return item;
                }}
            />
        </>
    )
}
export default Random