import { useEffect, useState, useRef } from "react";
import VariableTextarea from "../../../GlobalComponent/VariableTextarea";

function AddLog(props) {
    const [dataState, setDataState] = useState(props?.nodeData?.data?.options ?? {
		content: '',
    })
    const textareaRef = useRef(null);
    const onChangeValue = (key, target) => e => {
        if (e?.type === 'change') {
            setDataState((state) => ({...state, [key]: target ? e.target[target] : e.target.value}))
        } else if (e?.type === 'click') {
            const textarea = textareaRef.current;
            const startPos = textarea.selectionStart;
            const value = e.target.getAttribute('value')
            const firstPart = dataState[key].slice(0, startPos);
            const secondPart = dataState[key].slice(startPos);
            const newValue = firstPart + "${" + value + "}" + secondPart
            setDataState((state) => ({...state, [key]: newValue}))
            setTimeout(() => {
                textarea.focus();
                textarea.setSelectionRange(startPos + value.length + 4, startPos + value.length + 3);
            }, 0);
        } else {
            setDataState((state) => ({...state, [key]: e}))
        }
    }
	useEffect(() => {
        props.handleSetDataBaseModal(dataState)
    }, [props, dataState])

    return (
        <VariableTextarea
            textareaRef={textareaRef}
            dataState={dataState.content}
            handleData={onChangeValue('content')}
            handleSelect={onChangeValue('content')}
        />
    );
}

export default AddLog;
