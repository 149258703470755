import React, { useContext } from 'react';
import { Handle } from 'reactflow';
import LoadIcon from '../../Icon/LoadIcon';
import './Normal.css'
import { ThemeIcon, Text, ActionIcon } from '@mantine/core';
import { IconEdit, IconTrash } from '@tabler/icons';
import { IconPlayerPlay } from '@tabler/icons';
import { AppSettingsContext } from '../../../core/context/AppSettingsContext';

const Normal = ({ id, data, isConnectable }) => {
    const { nodeActive } = useContext(AppSettingsContext)
    const isActive = nodeActive?.id === id
    const handleRemoveNode = (e) => {
        e.stopPropagation();
        data.onRemoveNode(id);
    }

    const handleOpenNode = (e) => {
        e.stopPropagation();
        data.onUpdateNode(id)
    }

    const handleStartNode = (e) => {
        e.stopPropagation();
        data.onStartOneNode(id)
    }

    return (
        <div className={`node-normal ${isActive && 'active'}`}>
            <div className='action-node'>
                <div className='left-node'>
                    <ActionIcon onClick={handleStartNode} color="green" size="sm" radius="xl">
                        <IconPlayerPlay size={14} />
                    </ActionIcon>
                    <ActionIcon onClick={handleOpenNode} color="blue" size="sm" radius="xl">
                        <IconEdit size={14} />
                    </ActionIcon>
                </div>
                <ActionIcon onClick={handleRemoveNode} color="red" size="sm" radius="xl">
                    <IconTrash size={14} />
                </ActionIcon>
            </div>
            <Handle
                id={`in-${id}`}
                type="target"
                position="left"
                style={{ background: "var(--input)", left: "-6px" }}
                isConnectable={isConnectable}
            />
            <div className='node-normal-body'>
                <ThemeIcon className="node-icon">
                    <LoadIcon icon={data.icon} size={16} />
                </ThemeIcon>
                <div style={{ display: 'flex', alignItems: 'end' }}>
                    <Text ml="xs" size="0.8rem">{data.label}</Text>
                    {
                        data?.options?.description ? (
                            <span style={{ fontSize: '0.7rem', marginLeft: 4 }}>({data.options.description})</span>
                        ) : null
                    }
                </div>
            </div>
            <Handle
                id={`success-${id}`}
                type="source"
                position="right"
                style={{ background: "var(--output)", top: "15px" }}
                isConnectable={isConnectable}
            />
            <Handle
                id={`error-${id}`}
                type="source"
                position="right"
                style={{ background: "var(--output-bottom)", top: "32px" }}
                isConnectable={isConnectable}
            />
        </div>
    );
}

export default Normal;
