import { useEffect, useState } from "react";
import VariableSelectWrite from "../../../GlobalComponent/VariableSelectWrite";
import { Box } from "@mantine/core";

function ClearCookies(props) {
    const [dataState, setDataState] = useState(props?.nodeData?.data?.options ?? {
        domain: '',
    })
    const onChangeValue = (key, target) => e => {
        if (e?.type === 'change') {
            setDataState((state) => ({...state, [key]: target ? e.target[target] : e.target.value}))
        } else if (e?.type === 'click') {
            const newValue = e.newValue
            setDataState((state) => ({...state, [key]: newValue}))
        } else {
            setDataState((state) => ({...state, [key]: e}))
        }
    }
    useEffect(() => {
        props.handleSetDataBaseModal(dataState)
    }, [props, dataState])

    return (
        <div>
            <VariableSelectWrite
                label="Domain"
                dataState={dataState.domain}
                handleData={onChangeValue('domain')}
                handleSelect={onChangeValue('domain')}
                placeholder="Ex: facebook.com"
            />
            <Box
                sx={(theme) => ({
                    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
                    textAlign: 'center',
                    padding: theme.spacing.sm,
                    borderRadius: theme.radius.md,
                    cursor: 'pointer',
            
                    '&:hover': {
                    backgroundColor:
                        theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1],
                    },
                    marginTop: 12,
                })}
            >
                Leave blank if you want to clear all browser cookies
            </Box>
        </div>
    );
}

export default ClearCookies;