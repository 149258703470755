import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import vi from './vi.json'
import en from './en.json'
import cn from './cn.json'

// Tạo các tài nguyên dịch thuật
const resources = {
  en: {
    translation: en
  },
  vi: {
    translation: vi
  },
  cn: {
    translation: cn
  }
};

i18n
  .use(initReactI18next) // Pass i18n instance to react-i18next.
  .init({
    resources,
    lng: "en", // Ngôn ngữ mặc định
    fallbackLng: "en", // Ngôn ngữ dự phòng

    interpolation: {
      escapeValue: false // React đã bảo vệ khỏi XSS
    }
  });

export default i18n;
