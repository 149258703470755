import { useEffect, useState } from "react";
import VariableSelect from "../../../GlobalComponent/VariableSelect";
import VariableSelectWrite from './../../../GlobalComponent/VariableSelectWrite';
import VariableSelectCreate from "../../../GlobalComponent/VariableSelectCreate";
import { Button } from "@mantine/core";

const REGEXP_TEMPLATE = [
    { value: String.raw`\d{6,6}`, name: "6 Digits" },
    {
        value: 'https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)',
        name: "Link Url"
    },
    {
        value: String.raw`[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+`,
        name: "Email"
    },
]

function ExtractionInText(props) {
    const [dataState, setDataState] = useState(props?.nodeData?.data?.options ?? {
        data: '',
        extractRules: '',
        saveTo: '',
        index: 0,
    })
    const onChangeValue = (key, target) => e => {
        if (e?.type === 'change') {
            setDataState((state) => ({...state, [key]: target ? e.target[target] : e.target.value}))
        } else if (e?.type === 'click') {
            const newValue = e.newValue
            setDataState((state) => ({...state, [key]: newValue}))
        } else {
            setDataState((state) => ({...state, [key]: e}))
        }
    }
    useEffect(() => {
        props.handleSetDataBaseModal(dataState)
    }, [props, dataState])

    return (
        <div>
            <VariableSelect
                label="Data"
                placeholder="Select"
                value={dataState.data}
                handleChange={onChangeValue('data')}
            />
            <VariableSelectWrite
                label="Extract rules"
                dataState={dataState.extractRules}
                handleData={onChangeValue('extractRules')}
                handleSelect={onChangeValue('extractRules')}
            />
            <div style={{ marginTop: 8, marginBottom: 8 }}>
                {
                    REGEXP_TEMPLATE.map(item => (
                        <Button
                            size="xs"
                            compact
                            style={{ marginRight: 8 }}
                            key={item.name}
                            onClick={() => setDataState(state => ({ ...state, extractRules: item.value }))} variant="outline"
                        >
                            {item.name}
                        </Button>
                    ))
                }
            </div>
            <VariableSelectWrite
                label="Index result"
                dataState={dataState.index || ''}
                handleData={onChangeValue('index')}
                handleSelect={onChangeValue('index')}
            />
            <VariableSelectCreate
                placeholder="Save data to variable name"
                value={dataState.saveTo}
                label="Save to"
                handleChange={onChangeValue('saveTo')}
            />
        </div>
    );
}

export default ExtractionInText;
