import { SegmentedControl } from "@mantine/core";
import React, { useEffect, useState } from "react";
import VariableSelect from "../../../GlobalComponent/VariableSelect";
import VariableSelectCreate from "../../../GlobalComponent/VariableSelectCreate";
import VariableSelectWrite from "../../../GlobalComponent/VariableSelectWrite";

export default function HandleDialog(props) {
  const [dataState, setDataState] = useState(
    props?.nodeData?.data?.options ?? {
      action: "accept", // dismiss, accept, ignore
      defaultValueSaveTo: "",
      messageSaveTo: "",
      typeSaveTo: "",
      acceptValue: "",
    }
  );
  const onChangeValue = (key) => (e) => {
    let value
    if (e?.type === "change") {
      value = e.target.value;
    } else if (e?.type === "click") {
      const newValue = e.newValue;
      value = newValue;
    } else {
      value = e;
    }
    setDataState((oldState) => ({ ...oldState, [key]: value }));
  };
  useEffect(() => {
    props.handleSetDataBaseModal(dataState)
  }, [props, dataState])

  return (
    <div>
      <label style={{ display: 'block', marginBottom: 8 }} className="mantine-InputWrapper-label mantine-Textarea-label mantine-ittua2">Action</label>
      <SegmentedControl
        value={dataState.action}
        onChange={onChangeValue('action')}
        data={[
          { label: "No handle", value: "ignore" },
          { label: "Accept", value: "accept" },
          { label: "Dismiss", value: "dismiss" },
        ]}
      />
      {
        dataState.action === "accept" && (
          <VariableSelectWrite
            style={{marginTop: "20px"}}
            label="Prompt input"
            dataState={dataState.acceptValue}
            onValueChange={onChangeValue('acceptValue')}
            multiple={true}
          />
        )
      }
      <VariableSelectCreate
        style={{marginTop: "20px"}}
        label="Save message to"
        value={dataState.messageSaveTo}
        handleChange={onChangeValue('messageSaveTo')}
        multiple={true}
      />
      <VariableSelectCreate
        style={{marginTop: "20px"}}
        label="Save type to"
        value={dataState.typeSaveTo}
        handleChange={onChangeValue('typeSaveTo')}
        multiple={true}
      />
      <VariableSelectCreate
        style={{marginTop: "20px"}}
        label="Save default value to"
        value={dataState.defaultValueSaveTo}
        handleChange={onChangeValue('defaultValueSaveTo')}
        multiple={true}
      />
    </div>
  );
}
