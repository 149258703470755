import { Popover, TextInput, useMantineTheme, ScrollArea, Input, ActionIcon } from "@mantine/core";
import { IconSelector, IconX } from "@tabler/icons";
import { useState } from "react";
import styles from "../GlobalStyles/styleInputSearch.module.css"

const styleHover = {
    light: {
        backgroundColor: '#f1f3f5' 
    },
    dark: {
        backgroundColor: '#373A40'
    },
}

function ComponentCombobox({ disabled, style, className, label, data, placeholder, withAsterisk, handleData, dataState }) {
    const [openDropdown, setOpenDropdown] = useState(false)

    const handleDropdown = () => {
        setOpenDropdown(!openDropdown)
    }

    const onClickItem = (item) => {
        handleData(item)
        setOpenDropdown(false)
    }

    const rightSection = (<IconSelector size={16} stroke={1.5}/>)

    return (
        <>
            <Input.Wrapper
                id="input-demo2"
                withAsterisk={withAsterisk}
                label={label}
            >
                <Popover disabled={disabled} opened={openDropdown} width="target" position="bottom" shadow="md">
                    <Popover.Target>
                        <TextInput
                            style={style}
                            className={className}
                            placeholder={placeholder}
                            onClick={handleDropdown}
                            value={dataState}
                            onChange={(event) => {
                                handleData(event.currentTarget.value)
                            }}
                            onBlur={() => setOpenDropdown(false)}
                            rightSection={
                                typeof dataState === 'string' && dataState !== '' ? (
                                    <ActionIcon onClick={() => {
                                        setOpenDropdown(false);
                                        handleData('')
                                    }} variant="transparent" ><IconX size={16} />
                                    </ActionIcon>
                                ) : rightSection
                            }
                        />
                    </Popover.Target>
                    <Popover.Dropdown>
                        <div style={{ maxHeight: '220px', display: 'flex'}}>
                            <ScrollArea style={{ flex: '1'}}>
                                <div className={styles.select_itemsWrapper} style={{ flexDirection: 'column' }}>
                                    {
                                        data.length ? data.map((item, index) => <ItemChoose 
                                            key={index} 
                                            item={item}
                                            onClickItem={onClickItem}
                                        />): (
                                        <div className={styles.select_item} style={{ textAlign: 'center' }}>
                                            Nothing found
                                        </div>)
                                    }
                                </div>
                            </ScrollArea>
                        </div>
                    </Popover.Dropdown>
                </Popover>
            </Input.Wrapper>
        </>
    )
}

export default ComponentCombobox;

function ItemChoose ({item, onClickItem}) {
    const theme = useMantineTheme();
    const [styles, setStyles] = useState({})
    const setStyleHover = (isHover) => {
        setStyles(isHover ? (theme.colorScheme !== 'dark' ? styleHover.light : styleHover.dark) : {})
    }

    const truncateString = (str , maxLength) => {
        if (str?.length > maxLength) {
            return str.substring(0, maxLength - 3) + '...';
        } else {
            return str;
        }
    }
    return (
        <>
            {
                <div
                    className={theme.colorScheme === 'dark' ? "select-item-dark-mode " : "select-item"}
                    style={styles} 
                    onMouseDown={(e) => e.preventDefault()}
                    onMouseEnter={() => setStyleHover(true)}
                    onMouseLeave={() => setStyleHover(false)}
                    onClick={() => onClickItem(item)}
                >
                    <div style={{ display:"flex", justifyContent:"space-between", width:'100%' }}>
                        <div>{truncateString(item.label,45)}</div> 
                        {
                            !!item.folder_script_name ? (
                                <div>({truncateString(item.folder_script_name, 20)})</div> 
                            ):(
                                <div>No folder</div> 
                            )
                        }
                    </div>
                </div>
            }
        </>
        
    )
}
