import { Button, Grid, SegmentedControl, Select, Switch } from "@mantine/core"
import { useContext, useEffect, useMemo, useRef, useState } from "react"
import VariableSelectWrite from "../../../GlobalComponent/VariableSelectWrite"
import { LIST_OS, LIST_SCREEN_ANDROID, LIST_SCREEN_IOS, LIST_SCREEN_MAC, LIST_SCREEN_WIN, SWITCH_FIELDS } from "../../../../constants/newProfile.const"
import { fetchDefaultConfig, getUserSettings } from "../../../../request/Automation"
import { sample, uniqBy } from "lodash"
import { AppSettingsContext } from "../../../../core/context/AppSettingsContext"
import { versions } from "../../../../core/Navbar/automationVersion"

function StartConfig(props) {
    const { settings, userAgentConfig } = useContext(AppSettingsContext)
    const userAgent = userAgentConfig.current
    const [dataState, setDataState] = useState({
        isCreateNewProfile: props?.nodeData?.data?.options?.isCreateNewProfile ?? false,
        configProvider: props?.nodeData?.data?.options?.configProvider ?? "configuration",
        os: props?.nodeData?.data?.options?.os ?? "win",
        browser: props?.nodeData?.data?.options?.browser ?? 'chrome',
        version: props?.nodeData?.data?.options?.version ?? "",
        userAgent: props?.nodeData?.data?.options?.userAgent ?? "",
        canvas: props?.nodeData?.data?.options?.canvas ?? true,
        webGLImage: props?.nodeData?.data?.options?.webGLImage ?? true,
        audioContext: props?.nodeData?.data?.options?.audioContext ?? true,
        webGLMetadata: props?.nodeData?.data?.options?.webGLMetadata ?? true,
        clientRectsEnable: props?.nodeData?.data?.options?.clientRectsEnable ?? true,
        noiseFont: props?.nodeData?.data?.options?.noiseFont ?? true,
        languages: props?.nodeData?.data?.options?.languages ?? "en-us,vi;q=0.9",
        resolution: props?.nodeData?.data?.options?.resolution ?? "1366x768",
        proxy: props?.nodeData?.data?.options?.proxy ?? "",
        command: props?.nodeData?.data?.options?.command ?? "",
        changeFingerprint: props?.nodeData?.data?.options?.changeFingerprint ?? false,
        defaultConfigId: props?.nodeData?.data?.options?.defaultConfigId ?? null,
        storeMode: props?.nodeData?.data?.options?.storeMode ?? 'cloud',
    })
    const [defaultConfig, setDefaultConfig] = useState([])
    const onChangeValue = (key, target) => e => {
        if (e?.type === 'change') {
            setDataState((state) => ({...state, [key]: target ? e.target[target] : e.target.value}))
        } else if (e?.type === 'click') {
            const newValue = e.newValue || ""
            setDataState((state) => ({...state, [key]: newValue}))
        } else {
            setDataState((state) => ({...state, [key]: e}))
        }
    }
	useEffect(() => {
        let newState = { ...dataState }
        for (const key in newState) {
            if (newState[key] === "" || newState[key] === null) {
                delete newState[key]
            }
        }
        props.handleSetDataBaseModal(newState)
    }, [props, dataState])

    const getDefaultConfig = async () => {
        const configs = await fetchDefaultConfig()
        const configOptions = configs.map(config => ({ label: config.name, value: config.id }))
        setDefaultConfig(configOptions)
        if (!dataState.defaultConfigId) {
            setDataState((s) => ({...s, defaultConfigId: configOptions[0]?.value}))
        }
    }

    useEffect(() => {
        getDefaultConfig()
    }, [])

    const handleRandomResolution = (os) => {
        let newScreen
        switch (os) {
            case 'mac':
                newScreen = LIST_SCREEN_MAC
            break;
            case 'android':
                newScreen = LIST_SCREEN_ANDROID
            break;
            case 'ios':
                newScreen = LIST_SCREEN_IOS
            break;
            default:
                newScreen = LIST_SCREEN_WIN
            break;
        }
        const rdScreenSize = sample(newScreen)
        setDataState((s) => ({...s, resolution: rdScreenSize.value}))
    }

    const screenList = useMemo(() => {
        const sizes = [...LIST_SCREEN_ANDROID, ...LIST_SCREEN_MAC, ...LIST_SCREEN_WIN, ...LIST_SCREEN_IOS]
        return uniqBy(sizes, 'value').sort((a, b) => Number(a.value?.split('x')[0]) - Number(b.value?.split('x')[0]))
    }, [])

    return (
        <div>
            <Switch
                checked={dataState.isCreateNewProfile}
                label="Create new profile"
                size="md"
                onChange={e => setDataState((s) => ({...s, isCreateNewProfile: e.target.checked}))}
            />

            {
                dataState.isCreateNewProfile ? (
                    <>
                        {
                            settings.autoVersion >= versions[2].autoVersion && (<>
                                <label className="mantine-InputWrapper-label mantine-Textarea-label mantine-ittua2">Save Browser in</label>
                                <Select
                                    value={dataState.storeMode || "cloud"}
                                    placeholder="Pick one"
                                    data={[{value: 'local', label: 'Local'}, {value: 'cloud', label: 'Cloud'}]}
                                    onChange={val => setDataState((s) => ({...s, storeMode: val}))}
                                />
                            </>)
                        }
                        <div style={{ marginTop: 12, marginBottom: 12, textAlign: 'center' }}>
                            <SegmentedControl
                                data={[
                                    { label: 'Configuration', value: 'configuration' },
                                    { label: 'Use default config', value: 'default-config' },
                                ]}
                                value={dataState.configProvider}
                                onChange={value => setDataState((state) => ({ ...state, configProvider: value }))}
                            />
                        </div>
                        {
                            (dataState.configProvider === "configuration" || !dataState.configProvider) ? (
                                <>
                                    <label className="mantine-InputWrapper-label mantine-Textarea-label mantine-ittua2" >Operating System</label>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-around',
                                            marginTop: 8
                                        }}
                                    >
                                        {
                                            LIST_OS.map(os => (
                                                <Button
                                                    key={os.label}
                                                    leftIcon={os.icon}
                                                    onClick={() => {
                                                        setDataState(s => ({ ...s, version: null, os: os.value, browser: '' }))
                                                        handleRandomResolution(os.value)
                                                    }}
                                                    variant={dataState.os === os.value ? 'filled' : 'default'}
                                                >{os.label}</Button>
                                            ))
                                        }
                                    </div>

                                    <label className="mantine-InputWrapper-label mantine-Textarea-label mantine-ittua2">Browser</label>
                                    <Select
                                        value={dataState.browser}
                                        placeholder="Pick one"
                                        clearable
                                        data={Object.keys(userAgent.os[dataState.os]['browsers'] || {}).map(i => ({ value: i, label: i }))}
                                        onChange={val => setDataState((s) => ({...s, browser: val, version: ''}))}
                                    />
                                    <label className="mantine-InputWrapper-label mantine-Textarea-label mantine-ittua2">Browser version</label>
                                    <Select
                                        value={dataState.version || ""}
                                        placeholder="Pick one"
                                        clearable
                                        data={Object.keys(userAgent.os[dataState.os]['browsers'][dataState.browser] || {}).map(i => ({ value: i, label: i }))}
                                        onChange={val => setDataState((s) => ({...s, version: val}))}
                                    />

                                    <VariableSelectWrite
                                        label="User Agent"
                                        dataState={dataState.userAgent || ""}
                                        handleData={onChangeValue('userAgent')}
                                        handleSelect={onChangeValue('userAgent')}
                                    />

                                    <Grid style={{ marginTop: 12 }}>
                                        {
                                            SWITCH_FIELDS.map(field => (
                                                <Grid.Col key={field.key} span={4}>
                                                    <Switch
                                                        label={field.label}
                                                        checked={!!dataState[field.key]}
                                                        onChange={e => setDataState((s) => ({...s, [field.key]: e.target.checked}))}
                                                    />
                                                </Grid.Col>
                                            ))
                                        }
                                    </Grid>

                                    <VariableSelectWrite
                                        label="Languages"
                                        dataState={dataState.languages || ""}
                                        handleData={onChangeValue('languages')}
                                        handleSelect={onChangeValue('languages')}
                                    />

                                    <label className="mantine-InputWrapper-label mantine-Textarea-label mantine-ittua2">Resolution</label>
                                    <Select
                                        placeholder="Pick one"
                                        clearable
                                        data={screenList.map(i => (
                                            {value: i.value, label: i.label}
                                        ))}
                                        onChange={val => setDataState((s) => ({...s, resolution: val}))}
                                        value={dataState.resolution || ""}
                                    />
                                </>
                            ) : (
                                <>
                                    <label className="mantine-InputWrapper-label mantine-Textarea-label mantine-ittua2">Default config</label>
                                    <Select
                                        placeholder="Pick one"
                                        data={defaultConfig}
                                        onChange={val => setDataState((s) => ({...s, defaultConfigId: val}))}
                                        value={dataState.defaultConfigId || ""}
                                    />
                                </>
                            )
                        }
                    </>
                ) : null
            }
            {
                !dataState.isCreateNewProfile ? (
                    <Switch
                        style={{ marginTop: 20 }}
                        checked={dataState.changeFingerprint}
                        label="Change Fingerprint"
                        size="md"
                        onChange={e => setDataState((s) => ({...s, changeFingerprint: e.target.checked}))}
                    />
                ) : null
            }

            <VariableSelectWrite
                label="Proxy"
                dataState={dataState.proxy || ""}
                handleData={onChangeValue('proxy')}
                handleSelect={onChangeValue('proxy')}
				placeholder='SOCKS5|192.168.30.51|8080|123|121'
            />

            <VariableSelectWrite
                label="Command line open profile"
                dataState={dataState.command || ""}
                handleData={onChangeValue('command')}
                handleSelect={onChangeValue('command')}
            />

        </div>
    );
}

export default StartConfig;
