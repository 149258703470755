import {createContext, useState} from "react";

const ScriptContext = createContext();
function ScriptProvider({children}) {
    const [scriptId, setScriptId] = useState(null);
    const [scriptName, setScriptName] = useState(null);
    const [scriptPassword, setScriptPassword] = useState(null);
    const [folder_script_id, set_folder_script_id] = useState(null);

    const clearData = () => {
        setScriptId(null);
        setScriptName(null);
        setScriptPassword(null);
        set_folder_script_id(null)
    }

    const value = {
        scriptId,
        scriptName,
        scriptPassword,
        folder_script_id,
        setScriptId,
        setScriptName,
        setScriptPassword,
        clearData,
        set_folder_script_id
    }

    return (
        <ScriptContext.Provider value={value}>
            {children}
        </ScriptContext.Provider>
    )
}

export {ScriptContext, ScriptProvider}