import { useEffect, useState } from "react"
import VariableSelectWrite from "../../../GlobalComponent/VariableSelectWrite"
import { Radio } from "@mantine/core"
import VariableNumberInput from "../../../GlobalComponent/VariableNumberInput"

export const DEFAULT_SWITCH_EXTENSION_POPUP_DATA = {
    switchPage: "extensionPopupPage",
    elementSelect: "",
    timeout: 30000,
}

function SwitchExtensionPopup(props) {
    const [dataState, setDataState] = useState(props?.nodeData?.data?.options ?? DEFAULT_SWITCH_EXTENSION_POPUP_DATA)
    const onChangeValue = (key, target) => e => {
        if (e?.type === 'change') {
            setDataState((state) => ({...state, [key]: target ? e.target[target] : e.target.value}))
        } else if (e?.type === 'click') {
            const newValue = e.newValue
            setDataState((state) => ({...state, [key]: newValue}))
        } else {
            setDataState((state) => ({...state, [key]: e}))
        }
    }
	useEffect(() => {
        props.handleSetDataBaseModal(dataState)
    }, [props, dataState])
    return (
        <div>
            <Radio.Group
                value={dataState.switchPage}
                label="Switch page"
                onChange={onChangeValue("switchPage")}
                name="element-exists"
            >
                <Radio value="extensionPopupPage" label="Extension popup page" />
                <Radio value="mainPage" label="Main page" />
            </Radio.Group>
            {
                dataState.switchPage === "extensionPopupPage" &&
                <VariableSelectWrite
                    dataState={dataState.elementSelect} label="Select element" placeholder="Please enter the element, such as #email input"
                    setDataState={onChangeValue("elementSelect")}
                    handleData={onChangeValue("elementSelect")}
                    handleSelect={onChangeValue("elementSelect")}
                />
            }
            <VariableNumberInput
                label="Timeout waiting (milliseconds)"
                dataState={dataState.timeout}
                handleData={onChangeValue("timeout")}
                isMillisecond={true}
            />
        </div>
    );
}

export default SwitchExtensionPopup;
