import React, { useEffect, useState } from 'react';
import VariableSelectWrite from "../../../GlobalComponent/VariableSelectWrite";

const NewTab = (props) => {
    const [dataState, setDataState] = useState(props?.nodeData?.data?.options ?? {
        newTab: '',
    })
    const onChangeValue = (key, target) => e => {
        if (e?.type === 'change') {
            setDataState((state) => ({...state, [key]: target ? e.target[target] : e.target.value}))
        } else if (e?.type === 'click') {
            const newValue = e.newValue
            setDataState((state) => ({...state, [key]: newValue}))
        } else {
            setDataState((state) => ({...state, [key]: e}))
        }
    }
    useEffect(() => {
        props.handleSetDataBaseModal(dataState)
    }, [props, dataState])

    return (
        <>
            <VariableSelectWrite
                placeholder="Enter URL"
                label="URL"
                dataState={dataState.newTab}
                handleData={onChangeValue('newTab')}
                handleSelect={onChangeValue('newTab')}
            />
        </>
    );
}

export default NewTab;
