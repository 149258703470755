import React, {useEffect, useState} from "react";
import {Button, Flex, Text, TextInput} from "@mantine/core";
import {v4 as uuid} from "uuid";
import VariableSelectWrite from "../../../GlobalComponent/VariableSelectWrite";
const SetData = (props) => {
    const [dataState, setDataState] = useState(props?.nodeData?.data?.options?.dataArr ?? [])

    const handleChangeValue = (id) => (e) => {
        const { name, value } = e.target
        let result = dataState.map((item) => {
            if (item.id === id) {
                return { ...item, [name]: value }
            } else {
                return item
            }
        })
        setDataState(result)
    }
    const handleSetDataBaseModal = props.handleSetDataBaseModal;

    useEffect(() => {
        // const newData = dataState.filter(item => item.key !== '')
        const options = {
            dataArr: dataState
        }
        handleSetDataBaseModal(options)
    },[handleSetDataBaseModal, dataState])

    const handleRemoveInput = (id) => {
        if (dataState.length === 1) return
        setDataState((pre) => pre.filter(item => item.id !== id))
    }

    const handleAddInput = () => {
        setDataState((pre) => [...pre, {
            id: uuid(),
            key: "",
            value: ""
        }])
    }

    const handleSelector = (type, value, id) => {
        let newData = [...dataState];
        const result = newData.map(obj => {
            if (obj.id === id) {
                if (typeof value === 'string' || typeof value === 'number') {
                    return {...obj, [type]: value}
                } else {
                    return {...obj, [type]: value.target.value}
                }
            }
            return obj
        })
        setDataState(result)
    }

    return (
        <>
            {
                dataState?.map((ele, index) => (
                    <Flex
                        key={index}
                        direction={{ base: 'column', sm: 'row' }}
                        gap={{ base: 'sm', sm: 'lg' }}
                        justify={{ sm: 'center' }}
                    >
                        <TextInput
                            withAsterisk
                            placeholder="Key"
                            name="key"
                            mt="xs"
                            value={ele.key}
                            onChange={handleChangeValue(ele.id)}
                        />
                        <Text lh="2.4" mt="xs">&#61;</Text>
                        <VariableSelectWrite
                            placeholder="Value"
                            dataState={ele.value}
                            setDataState={handleSelector}
                            handleData={(e) => {
                                handleSelector('value', e, ele.id)
                            }}
                            handleSelect={(e) => {
                                handleSelector('value', e.newValue, ele.id)
                            }}
                        />
                        <Button.Group mt="xs">
                            <Button variant="default" onClick={() => handleRemoveInput(ele.id)}>&#8722;</Button>
                            <Button variant="default" onClick={handleAddInput}>&#43;</Button>
                        </Button.Group>
                    </Flex>
                ))
            }
        </>
    )
}

export default SetData