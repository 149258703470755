import { RunOtherScriptProvider } from "../../../../core/context/RunOtherScriptContext"
import IndexRunOtherScript from "./RunOtherScript/IndexRunOtherScript"
import { useEffect, useState } from "react";

function RunOtherScript(props) {
    const [dataState, setDataState] = useState(props?.nodeData?.data?.options??{
        script_key: '',
        description: ''
    })

    useEffect(() => {
        props.handleSetDataBaseModal(dataState)
    }, [props, dataState])

    return (
        <RunOtherScriptProvider>
            <IndexRunOtherScript dataState={dataState} setDataState={setDataState} />
        </RunOtherScriptProvider>
    );
}

export default RunOtherScript;
