import React, { useEffect, useRef, useState } from "react";
import { Checkbox, MultiSelect, Select, TextInput, Textarea } from "@mantine/core";
import { useForm } from "@mantine/form";

import { fetchListStatus, fetchListTag } from "../../../../request/Automation";
import VariableSelectWrite from './../../../GlobalComponent/VariableSelectWrite';
import VariableTextarea from "../../../GlobalComponent/VariableTextarea";

const DEFAULT_STATUS = [
    {value: -1, label: "Ban" },
    {value: -2, label: "Ready" },
    {value: -3, label: "New" },
]

const DEFAULT_BROWSER = [
    { value: "chrome", label: "Chrome" },
    { value: "opera", label: "Opera" },
    { value: "edge", label: "Edge" },
    { value: "brave", label: "Brave" },
    { value: "safari", label: "Safari" },
]

const MAX_LENGTH_NAME = 50;

function UpdateProfile({ nodeData, handleSetDataBaseModal }) {
    const [tags, setTags] = useState([]);
    const [status, setStatus] = useState([]);
    const textareaRef = useRef(null)
    const [formData, setFormData] = useState(nodeData.data.options);
    const form = useForm({
        initialValues: { name: nodeData.data.options.name.value },
        validate: {
          name: (value) => (value.length > MAX_LENGTH_NAME ? `Name profile must be less than ${MAX_LENGTH_NAME} characters.` : null),
        },
        validateInputOnChange: true,
    });

    const fetchOptionSet = async () => {
        const [tags, status] = await Promise.all([fetchListTag(), fetchListStatus()]);
        setStatus(status.map(i => ({ value: i.id, label: i.name })));
        setTags(tags.map(tag => ({ value: tag.label, label: tag.label })));
    };

    const updateFormData = (groupName, fieldName, value) => {
        const newFormData = { ...formData };
        newFormData[groupName][fieldName] = value;
        setFormData(newFormData);
    }

    const onChangeValue = (key) => e => {
        if (e?.type === 'change') {
            form.setFieldValue(key, e.target.value)
        } else if (e?.type === 'click') {
            const newValue = e.newValue || ""
            form.setFieldValue(key, newValue)
        } else {
            form.setFieldValue(key, e)
        }
    }

    const onChangeNoteValue = (e) => {
        if (e?.type === 'change') {
            updateFormData("note", "value", e.target.value)
        } else if (e?.type === 'click') {
            const textarea = textareaRef.current;
            const startPos = textarea.selectionStart;
            const value = e.target.getAttribute('value')
            const firstPart = formData.note.value.slice(0, startPos);
            const secondPart = formData.note.value.slice(startPos);
            const newValue = firstPart + "${" + value + "}" + secondPart
            updateFormData("note", "value", newValue)
            setTimeout(() => {
                textarea.focus();
                textarea.setSelectionRange(startPos + value.length + 4, startPos + value.length + 3);
            }, 0);
        } else {
            updateFormData("note", "value", e)
        }
    }

    useEffect(() => {
        fetchOptionSet();
        return () => handleSetDataBaseModal(formData);
    }, [])

    useEffect(() => {
        if (!form.isValid()) return;
        updateFormData("name", "value", form.values.name)
    }, [form.values.name])

    return (
        <div>
            <Checkbox
                label={<b>Profile name</b>}
                checked={formData.name.isUpdate}
                onChange={e => updateFormData("name", "isUpdate", e.target.checked)}
            />
            <VariableSelectWrite
                dataState={form.values.name || ""}
                label=""
                disabled={!formData.name.isUpdate}
                handleData={onChangeValue('name')}
                handleSelect={onChangeValue('name')}
                placeholder="Enter Profile name"
            />
            <span style={{ color: '#bf1650', fontSize: 14 }}>{form.errors.name ? form.errors.name : ""}</span>

            <Checkbox
                mt="lg" label={<b>Status</b>}
                checked={formData.status.isUpdate}
                onChange={e => updateFormData("status", "isUpdate", e.target.checked)}
            />
            <Select
                value={formData.status.value}
                disabled={!formData.status.isUpdate}
                onChange={e => updateFormData("status", "value", e)}
                clearable
                placeholder="Select status"
                data={[...DEFAULT_STATUS, ...status]}
            />
            {/* <Checkbox
                mt="lg" label={<b>Browser</b>}
                checked={formData.browser.isUpdate}
                onChange={e => updateFormData("browser", "isUpdate", e.target.checked)}
            />
            <Select
                value={formData.browser.value}
                disabled={!formData.browser.isUpdate}
                onChange={e => updateFormData("browser", "value", e)}
                clearable
                placeholder="Select browser"
                data={DEFAULT_BROWSER}
            /> */}

            <Checkbox
                mt="lg" label={<b>Tag</b>}
                checked={formData.tag.isUpdate}
                onChange={e => updateFormData("tag", "isUpdate", e.target.checked)}
            />
            <MultiSelect
                value={formData.tag.value || []}
                disabled={!formData.tag.isUpdate}
                onChange={e => updateFormData("tag", "value", e)}
                placeholder="Select tag"
                data={tags}
            />

            <Checkbox
                mt="lg" label={<b>Add note</b>}
                checked={formData.note.isUpdate}
                onChange={e => updateFormData("note", "isUpdate", e.target.checked)}
            />
            <VariableTextarea
                textareaRef={textareaRef}
                dataState={formData.note.value}
                disabled={!formData.note.isUpdate}
                handleData={onChangeNoteValue}
                handleSelect={onChangeNoteValue}
            />
        </div>
    );
}

export default UpdateProfile;
