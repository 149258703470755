import { useEffect, useState } from "react";
import VariableSelectWrite from "../../../GlobalComponent/VariableSelectWrite";
import VariableSelectCreate from "../../../GlobalComponent/VariableSelectCreate";
import VariableSelect from "../../../GlobalComponent/VariableSelect";

function ConvertJson(props) {
    const [dataState, setDataState] = useState(props?.nodeData?.data?.options ?? {
        convertVariable: '',
        saveTo: '',
    })
    const onChangeValue = (key, target) => e => {
        if (e?.type === 'change') {
            setDataState((state) => ({...state, [key]: target ? e.target[target] : e.target.value}))
        } else if (e?.type === 'click') {
            const newValue = e.newValue
            setDataState((state) => ({...state, [key]: newValue}))
        } else {
            setDataState((state) => ({...state, [key]: e}))
        }
    }
    useEffect(() => {
        props.handleSetDataBaseModal(dataState)
    }, [props, dataState])

    return (
        <div>
            <VariableSelect
                label="Convert variables"
                placeholder="Select"
                value={dataState.convertVariable}
                handleChange={onChangeValue('convertVariable')}
            />
            <VariableSelectCreate
                label="Save To"
                placeholder="Choose or create variable"
                value={dataState.saveTo}
                handleChange={onChangeValue('saveTo')}
            />
        </div>
    );
}

export default ConvertJson;
