import React, { useCallback, useRef, useState } from 'react';
import { Text, Modal, useMantineTheme, Container, Tabs } from '@mantine/core';

import "./BaseModel.css"
import Setting from '../GlobalComponent/Setting';

const nodeInstance = ["nodeVariables", "nodeStart", "pauseCode", "addComment", "addLog"]

function BaseModal({ show, setShowModal, bodyModal, nodeData, updateDataInNode, documentModal, setEdges }) {
    const theme = useMantineTheme();
    const formRef = useRef(nodeData?.data?.options);
    const settingRef = useRef();
    /**
     * Dùng useCallback để truyển vào trong các component con
     * Các component con có thể sử dụng handleSetDataBaseModal để prop vào useEffect lắng nghe sự thay đổi của form element
     * Tránh react-hooks/exhaustive-deps
     */
    const handleSetDataBaseModal = useCallback((data) => {
        formRef.current = data;
    }, [])

    const handleSetDataNodeSetting = useCallback((data) => {
        settingRef.current = data
    }, [])

    const handleClose = () => {
        setShowModal(false);
        updateDataInNode({...formRef.current, nodeSetting: settingRef.current});
    }

    return (
        <>
            <Modal
                zIndex={1000}
                opened={show}
                onClose={handleClose}
                title={<Text fw={500} fz="xl">{nodeData?.data?.label}</Text>}
                overlayColor={theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[2]}
                overlayOpacity={0.55}
                overlayBlur={0.5}
                overflow="inside"
                size="lg"
            >
                <Container>
                    <Tabs defaultValue="options">
                        <Tabs.List>
                            <Tabs.Tab value="options">Options</Tabs.Tab>
                            {!nodeInstance.includes(nodeData?.data?.type) && 
                                <Tabs.Tab value="setting">Setting</Tabs.Tab>
                            }
                            <Tabs.Tab value="document">Document</Tabs.Tab>
                        </Tabs.List>
                        <Tabs.Panel value="options" mt="sm" mb='sm'>
                            {
                                bodyModal && React.createElement(bodyModal, { handleSetDataBaseModal: handleSetDataBaseModal, nodeData: nodeData, setEdges })
                            }
                        </Tabs.Panel>
                        <Tabs.Panel value="setting" mt="sm">
                            {
                                bodyModal && 
                                <Setting 
                                    nodeData={nodeData} 
                                    handleSetDataNodeSetting={handleSetDataNodeSetting}
                                />
                            }
                        </Tabs.Panel>
                        <Tabs.Panel value="document" mt="sm">
                            {
                                documentModal && React.createElement(documentModal)
                            }
                        </Tabs.Panel>
                    </Tabs>
                </Container>
            </Modal>
        </>
    );
}

export default BaseModal;