import React, { useEffect, useRef, useState } from "react";
import {Grid, Input, Select, TextInput, Checkbox, Text, Button, Flex} from "@mantine/core";
import { IconFileUpload } from "@tabler/icons";
import VariableSelectCreate from "../../../GlobalComponent/VariableSelectCreate";
import VariableSelectWrite from "../../../GlobalComponent/VariableSelectWrite";
import { v4 as uuid } from "uuid"

const dataTypes = [
    { value: 'lineByLine', label: 'Line by line' },
    { value: 'lineByLineDelimiter', label: 'Line by line (with delimiter)' },
    { value: 'readAll', label: 'Read all'}
]

const ReadFile = (props) => {
    const inputRef = useRef(null)
    const [dataState, setDataState] = useState(props?.nodeData?.data?.options ? {
        ...props?.nodeData?.data?.options, multiOutputVariable: props?.nodeData?.data?.options.multiOutputVariable.map(item => ({
            ...item, uuid: uuid()
        }))
    } : {
        filePath: "",
        lineByLineDelimiter: "",
        outputVariable: "",
        readType: "lineByLine",
        random: false,
        delAfterRead: false,
        multiOutputVariable: [{
            value: "",
            uuid: uuid(),
        }]
    });

    const handleSelector = (type, value) => {
        if (typeof value === 'string') {
            setDataState({...dataState, [type]: value});
        } else if (typeof value === 'object' && value === null) {
            setDataState({...dataState, [type]: ""})
        } else {
            if (value.target.hasOwnProperty('checked')) {
                setDataState({...dataState, [type]: value.target.checked});
            } else {
                setDataState({...dataState, [type]: value.target.value});
            }
        }
    }

    useEffect(() => {
        props.handleSetDataBaseModal(dataState)
    }, [props, dataState])

    const handleUpload = () => {
        setDataState({...dataState, filePath: inputRef.current.files[0].path});
    }

    const handleAddOutput = () => {
        dataState.multiOutputVariable.push({ value: "", uuid: uuid() });
        setDataState({...dataState, multiOutputVariable: dataState.multiOutputVariable});
    }

    const handleRemoveOutput = (item) => {
        if (dataState.multiOutputVariable.length > 1) {
            let filtered = dataState.multiOutputVariable.filter(function(el) { return el.uuid !== item.uuid; });
            setDataState({...dataState, multiOutputVariable: filtered});
        } else {
            setDataState({...dataState, multiOutputVariable: [{
                    value: "",
                    uuid: uuid(),
                }]});
        }
    }

    const handleSaveOutput = (id, value) => {
        let objIndex = dataState.multiOutputVariable.findIndex((obj => obj.uuid === id));
        if (typeof value === 'string') {
            dataState.multiOutputVariable[objIndex].value = value;
        } else if (typeof value === 'object' && value === null) {
            dataState.multiOutputVariable[objIndex].value = ""
        }
        else {
            dataState.multiOutputVariable[objIndex].value = value.target.value;
        }
        setDataState({...dataState, multiOutputVariable: dataState.multiOutputVariable});
    }

    return (
        <>
            <div className="custom-wrapper">
                <div className="block-input">
                    <VariableSelectWrite
                        placeholder="Enter full path or upload file"
                        description="File path must be absolute path"
                        label="Path to the file"
                        dataState={dataState.filePath}
                        setDataState={handleSelector}
                        handleData={(e) => {
                            handleSelector('filePath', e)
                        }}
                        handleSelect={(e) => {
                            handleSelector('filePath', e.newValue)
                        }}
                    />
                </div>
                <Input
                    className="custom-file-input"
                    type="file"
                    ref={inputRef}
                    onChange={handleUpload}
                    icon={<IconFileUpload size={16} />}
                    mt="0px"
                    styles={(theme) => ({
                        input: {
                            paddingRight: "0px",
                            '&::-webkit-file-upload-button': {
                                visibility: 'hidden',
                            },
                            cursor: 'pointer',
                        },
                    })}
                >
                </Input>
            </div>
            <Select
                data={dataTypes}
                mt="xs"
                value={dataState.readType}
                label="Type"
                placeholder="Choose type"
                onChange={(e) => {
                    console.log("read")
                    handleSelector('readType', e)
                }}
            />
            {(() => {
                switch (dataState.readType) {
                    case 'lineByLineDelimiter':
                        return <Grid>
                                <Grid.Col span={12}>
                                    <TextInput
                                        style={{marginTop: "20px"}}
                                        mt="xs"
                                        label="Line by line delimiter"
                                        value={dataState.lineByLineDelimiter}
                                        onChange={(e) => {
                                            handleSelector('lineByLineDelimiter', e)
                                        }}
                                        placeholder="Enter delimiter - default comma (,)"
                                    />
                                </Grid.Col>
                            <Grid.Col span={12}>
                                <Checkbox
                                    label="Random"
                                    checked={dataState.random}
                                    onChange={(e) => {
                                        handleSelector('random', e)
                                    }}
                                />
                            </Grid.Col>
                            <Grid.Col span={12}>
                                <Checkbox
                                    label="Delete line after read"
                                    checked={dataState.delAfterRead}
                                    onChange={(e) => {
                                        handleSelector('delAfterRead', e)
                                    }}
                                />
                            </Grid.Col>
                            </Grid>
                    default:
                        return null
                }
            })()}
            {dataState.readType === "lineByLineDelimiter" && dataState.lineByLineDelimiter === "" && (
                <VariableSelectCreate
                    style={{marginTop: "20px"}}
                    label="Output Variable"
                    placeholder="Select items"
                    value={dataState.outputVariable}
                    handleChange={(e) => {
                        handleSelector('outputVariable', e)
                    }}
                />
            )}
            {(dataState.readType === "lineByLine" || dataState.readType === "readAll") && (
                <VariableSelectCreate
                    style={{marginTop: "20px"}}
                    label="Output Variable"
                    placeholder="Select items"
                    value={dataState.outputVariable}
                    handleChange={(e) => {
                        handleSelector('outputVariable', e)
                    }}
                />
            )}
            {dataState.readType === "lineByLineDelimiter" && dataState.lineByLineDelimiter !== "" && dataState.multiOutputVariable.length > 0 && <Text weight={500} style={{marginTop:"10px"}}>Variables map value (Ordering by index)</Text>}

            {dataState.readType === "lineByLineDelimiter" && dataState.lineByLineDelimiter !== "" && dataState.multiOutputVariable.length > 0 && dataState.multiOutputVariable.map((item) => {
                return <Flex
                    key={item.uuid}
                    mt="xs"
                    direction={{ base: 'column', sm: 'row' }}
                    gap={{ base: 'sm', sm: 'lg' }}
                    justify={{ sm: 'left' }}
                >
                    <VariableSelectCreate
                        value={item.value}
                        placeholder="Select items"
                        handleChange={(e) => {
                            handleSaveOutput(item.uuid, e)
                        }}
                        customCreate={true}
                        customFunc={handleSaveOutput}
                        order={item.uuid}
                    />
                    <Button.Group>
                        <Button variant="default" onClick={() => {
                            handleRemoveOutput(item)
                        }}>&#8722;</Button>
                        <Button variant="default" onClick={handleAddOutput}>&#43;</Button>
                    </Button.Group>
                </Flex>
            })
            }
        </>
    )
}

export default ReadFile;