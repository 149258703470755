export const BASE_URL = localStorage.getItem('base_url') ?? "https://v2-api.multibrowser.io/v2";
export const BASE_URL_V4 = localStorage.getItem('base_url_v4') ?? "https://v2-api.multibrowser.io/v4";
export const GET_ALL_FOLDER_BY_USER = '/script/get-all-folders-by-user'
export const SCRIPT = "/automation/script";
export const UPDATE_SCRIPT = "/automation/script/update";
export const LIST_PROFILE_NAME = "/browser/list-profile-name-paginate";

export const STATUS_PROFILE = '/status-profile';
export const TAG = '/tag';

export const USER_SETTINGS = '/setting/user';

export const DEFAULT_CONFIG = '/default-config';


//URL LOCAL
export const LOCAL_LIST_PROFILE_NAME = "/browser/list-profile-name-paginate"