import {ActionIcon, Flex, Input, NumberInput, Radio, Space, TextInput} from "@mantine/core";
import { IconCode, IconFileUpload } from "@tabler/icons";
import React, { useContext, useEffect, useRef, useState } from "react";
import VariableSelectWrite from "../../../GlobalComponent/VariableSelectWrite";
import VariableNumberInput from "../../../GlobalComponent/VariableNumberInput";

const FileUpload = (props) => {
    const fileRef = useRef(null)
    const folderRef = useRef(null)
    const [dataState, setDataState] = useState(props?.nodeData?.data?.options ?? {
        elementSelect: "",
        fixedElement: 1,
        attachment: "localFile",
        filePath: '',
        timeout: 30000,
    });

    const handleSelector = (type, value) => {
        if (typeof value === 'string' || typeof value === 'number') {
            setDataState({...dataState, [type]: value});
        } else {
            setDataState({...dataState, [type]: value.target.value});
        }
    }

    useEffect(() => {
        props.handleSetDataBaseModal(dataState)
    }, [props, dataState])

    const handleUpload = (isFolder = false) => {
        if (isFolder) {
            setDataState({...dataState, filePath: folderRef.current.files[0].path.slice(0, folderRef.current.files[0].path.lastIndexOf('\\'))});
        }
        else {
            setDataState({...dataState, filePath: fileRef.current.files[0].path});
        }
    }
    
    return (
        <>
            <VariableSelectWrite
                style={{marginBottom:"10px"}}
                dataState={dataState.elementSelect} label="Select element" placeholder="Please enter the element, such as #email input"
                setDataState={handleSelector}
                handleData={(e) => {
                    handleSelector('elementSelect', e)
                }}
                handleSelect={(e) => {
                    handleSelector('elementSelect', e.newValue)
                }}
            />
            <Flex
                mih={50}
                gap="md"
                justify="flex-start"
                align="flex-start"
                direction="row"
                wrap="wrap"
            >
                <VariableNumberInput
                    style={{width:"200px"}}
                    label="Element order"
                    dataState={dataState.fixedElement}
                    handleData={(e) => {
                        handleSelector('fixedElement', e)
                    }}
                />
            </Flex>
            <Radio.Group
                value={dataState.attachment}
                label="Choose selector type"
                onChange={(e) => {
                    handleSelector('attachment', e)
                }}
                withAsterisk
                name="element-exists"
            >
                <Radio value="localFile" label="Local file" />
                <Radio value="folderFileRandom" label="Folder file random" />
                <Radio value="networkFile" label="Network file" />
            </Radio.Group>
            {
                dataState.attachment === "localFile" &&
                <div className="custom-wrapper">
                    <div className="block-input">
                        <VariableSelectWrite
                            placeholder="Enter full path or upload file"
                            label="Path to the file"
                            dataState={dataState.filePath}
                            setDataState={handleSelector}
                            handleData={(e) => {
                                handleSelector('filePath', e)
                            }}
                            handleSelect={(e) => {
                                handleSelector('filePath', e.newValue)
                            }}
                        />
                    </div>
                    <Input
                        className="custom-file-input"
                        type="file"
                        ref={fileRef}
                        onChange={() => {
                            handleUpload(false)
                        }}
                        icon={<IconFileUpload size={16} />}
                        mt="0px"
                        styles={(theme) => ({
                            input: {
                                paddingRight: "0px",
                                '&::-webkit-file-upload-button': {
                                    visibility: 'hidden',
                                },
                                cursor: 'pointer',
                            },
                        })}
                    >
                    </Input>
                </div>
            }
            {
                dataState.attachment === "folderFileRandom" &&
                <div className="custom-wrapper">
                    <div className="block-input">
                        <VariableSelectWrite
                            placeholder="Enter full path or upload file"
                            label="Path to the file"
                            dataState={dataState.filePath}
                            setDataState={handleSelector}
                            handleData={(e) => {
                                handleSelector('filePath', e)
                            }}
                            handleSelect={(e) => {
                                handleSelector('filePath', e.newValue)
                            }}
                        />
                    </div>
                    <Input
                        className="custom-file-input"
                        type="file"
                        ref={folderRef}
                        directory=""
                        webkitdirectory=""
                        onChange={()=>{
                            handleUpload(true)
                        }}
                        icon={<IconFileUpload size={16} />}
                        mt="0px"
                        styles={(theme) => ({
                            input: {
                                paddingRight: "0px",
                                '&::-webkit-file-upload-button': {
                                    visibility: 'hidden',
                                },
                                cursor: 'pointer',
                            },
                        })}
                    >
                    </Input>
                </div>
            }
            {
                dataState.attachment === "networkFile" &&
                <VariableSelectWrite
                    placeholder="Enter link"
                    label="Path to the file"
                    dataState={dataState.filePath}
                    setDataState={handleSelector}
                    handleData={(e) => {
                        handleSelector('filePath', e)
                    }}
                    handleSelect={(e) => {
                        handleSelector('filePath', e.newValue)
                    }}
                />
            }
            <VariableNumberInput
                label="Timeout waiting (milliseconds)"
                dataState={dataState.timeout}
                handleData={(e) => {
                    handleSelector('timeout',e)
                }}
                isMillisecond={true}
            />
        </>
    )
}
export default FileUpload;