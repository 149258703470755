import {createContext, useState} from "react";
import {v4 as uuid} from "uuid";

const DataFlowContext = createContext();
function DataFlowProvider({children}) {
    const [comment, setComment] = useState({});

    const [nodeValue, setNodeValue] = useState({
        variables: [
            {
                id: uuid(),
                value: 'PROFILE_NAME',
                label: 'PROFILE_NAME',
                data: "",
                type: 'disabled',
            },
            {
                id: uuid(),
                value: 'PROFILE_ID',
                label: 'PROFILE_ID',
                data: "",
                type: 'disabled',
            }
        ]
    })

    const onCreateNewVariable = (variableName) => {
        const variableExist = nodeValue.variables.find(variable => variable.value === variableName);
        if (variableExist) return;
        const newVariable = {
            id: uuid(),
            value: variableName,
            label: variableName,
            data: "",
            name: ""
        }
        setNodeValue({
            variables: [...nodeValue.variables, newVariable]
        })
    }

    const value = {
        comment,
        nodeValue,
        setComment,
        onCreateNewVariable,
        setNodeValue
    }

    return (
        <DataFlowContext.Provider value={value}>
            {children}
        </DataFlowContext.Provider>
    )
}

export {DataFlowContext, DataFlowProvider}