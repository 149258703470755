import { useEffect, useState } from "react";
import VariableSelectWrite from "../../../GlobalComponent/VariableSelectWrite";


const SetUserAgent = (props) => {
    const [dataState, setDataState] = useState(props?.nodeData?.data?.options ?? {
        userAgent : '',
    });
    const handleChangeValue = (type, value) =>{
        if (typeof value === 'string' || typeof value === 'number') {
            setDataState({ ...dataState, [type]: value });
        } else {
            setDataState({ ...dataState, [type]: value.target.value });
        }
    }

    useEffect(() => {
        console.log(dataState);
        props.handleSetDataBaseModal(dataState)

    }, [props, dataState]);


    return (
        <>
            <VariableSelectWrite
                label="User Agent"
                placeholder=""
                dataState={dataState.userAgent}
                handleData={(e) => {
                    handleChangeValue('userAgent', e)
                }}
                handleSelect={(e) => {
                    handleChangeValue('userAgent', e.newValue)
                }}
            />
        </>
    );
}

export default SetUserAgent;