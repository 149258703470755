import { useEffect, useState } from "react";
import VariableSelectWrite from "../../../GlobalComponent/VariableSelectWrite";

const MouseWheel = (props) => {
    const [dataState, setDataState] = useState({ 
        deltaX: props?.nodeData?.data?.options.deltaX ??  0,
        deltaY: props?.nodeData?.data?.options.deltaY ??  0,
        randomStepFrom: props?.nodeData?.data?.options.randomStepFrom ??  400,
        randomStepTo: props?.nodeData?.data?.options.randomStepTo ??  1000,
        randomDelayWheelFrom: props?.nodeData?.data?.options.randomDelayWheelFrom ??  500,
        randomDelayWheelTo: props?.nodeData?.data?.options.randomDelayWheelTo ??  1000,
    })

    const handleSelector = (type, value) => {
        if (typeof value === 'string' || typeof value === 'number') {
            setDataState({...dataState, [type]: value});
        } else if (typeof value === 'boolean') {
            setDataState({...dataState, [type]: value});
        } else {
            setDataState({...dataState, [type]: value.target.value});
        }
    }

    const handleData = (type) => (value) => {
        if (typeof value === 'string' || typeof value === 'number') {
            setDataState({...dataState, [type]: value});
        } else {
            setDataState({...dataState, [type]: value.target.value});
        }
    }

    useEffect(() => {
        props.handleSetDataBaseModal(dataState)
    }, [props, dataState])

    return (
        <>
            <VariableSelectWrite
                label="Enter X coordinate"
                dataState={dataState.deltaX}
                handleData={handleData('deltaX')}
                handleSelect={(e) => {
                    handleSelector('deltaX', e.newValue)
                }}
            />
            <VariableSelectWrite
                label="Enter Y coordinate"
                dataState={dataState.deltaY}
                handleData={handleData('deltaY')}
                handleSelect={(e) => {
                    handleSelector('deltaY', e.newValue)
                }}
            />
            <label className="mantine-InputWrapper-label mantine-Textarea-label mantine-ittua2" style={{ marginTop: 16 }}>Speed:</label>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <VariableSelectWrite
                    label="Random wheel step from"
                    dataState={dataState.randomStepFrom}
                    handleData={handleData('randomStepFrom')}
                    handleSelect={(e) => {
                        handleSelector('randomStepFrom', e.newValue)
                    }}
                    style={{ marginTop: 0, flex: 1 }}
                />
                <VariableSelectWrite
                    label="Random wheel step to"
                    dataState={dataState.randomStepTo}
                    style={{ marginTop: 0, flex: 1, marginLeft: '12px' }}
                    handleData={handleData('randomStepTo')}
                    handleSelect={(e) => {
                        handleSelector('randomStepTo', e.newValue)
                    }}
                />
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <VariableSelectWrite
                    label="Delay wheel step from"
                    dataState={dataState.randomDelayWheelFrom}
                    handleData={handleData('randomDelayWheelFrom')}
                    handleSelect={(e) => {
                        handleSelector('randomDelayWheelFrom', e.newValue)
                    }}
                    style={{ flex: 1 }}
                />
                <VariableSelectWrite
                    label="Delay wheel step to"
                    dataState={dataState.randomDelayWheelTo}
                    style={{ flex: 1, marginLeft: '12px' }}
                    handleData={handleData('randomDelayWheelTo')}
                    handleSelect={(e) => {
                        handleSelector('randomDelayWheelTo', e.newValue)
                    }}
                />
            </div>
        </>
    )
}
export default MouseWheel