import {
  Button,
  Container,
  Modal,
  Text,
  useMantineTheme,
} from "@mantine/core";
import React, { useContext } from "react";
import { ListProfilesContext } from "../context/ListProfilesContext";
import ComponentInputSearch from "../../components/GlobalComponent/ComponentInputSearch";

const ModalStatus = ({show, setShowModal, setUuidProfile, handleStart}) => {
  const theme = useMantineTheme();
  const { listProfile, valueChoose, setValueChoose, setSearch } = useContext(ListProfilesContext)
  const handleSetValue = (item) => {
    if (typeof item === 'string') {
        setValueChoose(item)
        setSearch(item)
    } else if (typeof item === 'object') {
        setValueChoose(item.label)
    }
    let browserName = listProfile.find(ele => ele.value === item?.value)
    setUuidProfile({uuid : item?.value, browser_name: browserName?.label})
}

const handleClose = () => {
    setShowModal(false);
}

  return (
    <Modal
      zIndex={1000}
      opened={show}
      onClose={handleClose}
      overlayColor={
        theme.colorScheme === "dark"
          ? theme.colors.dark[9]
          : theme.colors.gray[2]
      }
      title={
        <Text fw={500} fz="xl">
          Run Node
        </Text>
      }
      overlayOpacity={0.55}
      overlayBlur={0.5}
      size="lg"
    >
      <Container>
        <div style={{ marginBottom: "12px" }}>
          <ComponentInputSearch
            listProfile={listProfile}
            placeholder="Choose a profile"
            label="Run on uuid"
            withAsterisk={true}
            handleData={(e) => handleSetValue(e)}
            dataState={valueChoose}
            setUuidProfile={setUuidProfile}
          />
          <div style={{ textAlign: "end", marginTop: "20px" }}>
            <Button onClick={handleStart} variant="outline" color="teal">
              Start
            </Button>
          </div>
        </div>
      </Container>
    </Modal>
  );
};
export default ModalStatus;
