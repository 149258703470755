import { ActionIcon, Button, Group, Input, Navbar, ScrollArea, Switch } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconVariable, IconX } from '@tabler/icons';
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { LinksGroup } from '../../components/NavbarLinksGroup/NavbarLinksGroup';
import { ScriptContext } from "../context/ScriptContext";
import { mockdata } from './menuData';
import './navbar.css';
import { useStyles } from './style';
import { AppSettingsContext } from '../context/AppSettingsContext';

const NavbarNested = (props) => {
    const text = useRef('')
    const ref = useRef(null)
    const { settings } = useContext(AppSettingsContext)
    const scriptContext = useContext(ScriptContext);
    const { classes } = useStyles();
    const [navItem, setNavItem] = useState(mockdata.map(item => ({ ...item, links: item.links.filter(link => link.data.version <= settings.autoVersion) })));
    const handleOpenNode = (index) => {
        const newState = [...navItem]
        newState[index].initiallyOpened = !newState[index].initiallyOpened
        setNavItem(newState)
    }

    useEffect(() => {
        setNavItem(mockdata.map(item => ({ ...item, links: item.links.filter(link => link.data.version <= settings.autoVersion) })))
    },[settings.autoVersion])

    const links = useMemo(
        () => navItem.map(
            (item, index) => <LinksGroup {...item} 
                key={item.label}
                index={index} 
                handleOpenNode={handleOpenNode} 
                handleDbClick={props.handleDbClick}
            />
    ), [navItem]);
    const form = useForm({
        initialValues: { name: scriptContext.scriptName ?? '' },

        // functions will be used to validate values at corresponding key
        validate: {
            name: (value) => (value.length < 1
                ? 'Name must have at least 1 letters'
                : value.length > 255
                    ? 'Name is too long'
                    : null
            ),
        },
    });

    useEffect(() => {
        form.setValues({
            name: scriptContext.scriptName ?? "",
        })
    }, [scriptContext])

    const handleOpenAllNodes = () => {
        const newData = [...navItem]
        for ( const i of newData) {
            if (!ref.current.checked) {
                i.initiallyOpened = false
            } else {
                i.initiallyOpened = true
            }
        }
        setNavItem(newData)
    }

   const handleClearInput = () => {
        text.current.value = ""
        setNavItem(mockdata.map(item => ({ ...item, links: item.links.filter(link => link.data.version <= settings.autoVersion) })))
   }

   const rightSection = (<ActionIcon onClick={handleClearInput} radius="xl"><IconX size="1rem"/></ActionIcon>)

   const handleFilter = (e) => {
        const text = e.target.value
        const newData = [...mockdata].map(item => ({ ...item, links: item.links.filter(link => link.data.version <= settings.autoVersion)}))
        let node = newData.map((element) => {
            if (text.toLowerCase() === "") {
                return element
            } else {
                return {...element, links: element.links.filter((nodeLink) => 
                    nodeLink.data.label.toLowerCase().includes(text.toLowerCase())
                )}
            }
        })
        setNavItem(node)
   }

    return (
        <Navbar width={{ sm: 360 }} p="md" className={classes.navbar}>
            <Navbar.Section className={classes.header}>
                <Group position="apart" align="center">
                    <Input
                        ref={text}
                        placeholder="Search"
                        rightSection={rightSection}
                        onChange={handleFilter}
                        />
                        <Switch className='d-flex' ref={ref} checked={navItem.some((item) => item.initiallyOpened)} onChange={handleOpenAllNodes} />
                </Group>
                <div style={{ marginTop: '20px'}}>
                    <Group position="apart" align="center">
                        <Button
                            leftIcon={<IconVariable size={14} />}
                            variant="default"
                            onClick={props.handleShowVariables}
                        >
                            Variables
                        </Button>
                    </Group>
                </div>
            </Navbar.Section>
            <Navbar.Section grow className={classes.links} component={ScrollArea}>
                <div className={classes.linksInner}>{links}</div>
            </Navbar.Section>

            <Navbar.Section className={classes.footer}>
            </Navbar.Section>
        </Navbar>
    );
}

export default NavbarNested;